export let gene_map:Array<any> = [
    {"name":"Class",              "bits": 4},
    {"name":"0.1",                "bits": 4},
    {"name":"0.2",                "bits": 4},
    {"name":"Region",             "bits": 1},
    {"name":"0.4",                "bits": 3},
    {"name":"Title",              "bits": 2},
    {"name":"0.6",                "bits": 14},

    {"name":"1.0",                "bits": 4},
    {"name":"Pattern",            "bits": 4},
    {"name":"???",                "bits": 2},
    {"name":"Pattern R1",         "bits": 4},
    {"name":"???",                "bits": 2},
    {"name":"Pattern R2",         "bits": 4},
    {"name":"Color",              "bits": 4},
    {"name":"Color R1",           "bits": 4},
    {"name":"Color R2",           "bits": 4},

    {"name":"Eye Mystic",         "bits": 2},
    {"name":"Eye Class",          "bits": 4},
    {"name":"Eyes",               "bits": 5},
    {"name":"???",                "bits": 1},
    {"name":"Eye Class R1",       "bits": 4},
    {"name":"Eyes R1",            "bits": 5},
    {"name":"???",                "bits": 1},
    {"name":"Eyes Class R2",      "bits": 4},
    {"name":"Eyes R2",            "bits": 5},
    {"name":"???",                "bits": 1},

    {"name":"Mouth Mystic",       "bits": 2},
    {"name":"Mouth Class",        "bits": 4},
    {"name":"Mouth",              "bits": 5},
    {"name":"???",                "bits": 1},
    {"name":"Mouth Class R1",     "bits": 4},
    {"name":"Mouth R1",           "bits": 5},
    {"name":"???",                "bits": 1},
    {"name":"Mouth Class R2",     "bits": 4},
    {"name":"Mouth R2",           "bits": 5},
    {"name":"???",                "bits": 1},

    {"name":"Ears Mystic",        "bits": 2},
    {"name":"Ears Class",         "bits": 4},
    {"name":"Ears",               "bits": 5},
    {"name":"???",                "bits": 1},
    {"name":"Ears Class R1",      "bits": 4},
    {"name":"Ears R1",            "bits": 5},
    {"name":"???",                "bits": 1},
    {"name":"Ears Class R2",      "bits": 4},
    {"name":"Ears R2",            "bits": 5},
    {"name":"???",                "bits": 1},

    {"name":"Horn Mystic",        "bits": 2},
    {"name":"Horn Class",         "bits": 4},
    {"name":"Horn",               "bits": 5},
    {"name":"???",                "bits": 1},
    {"name":"Horn Class R1",      "bits": 4},
    {"name":"Horn R1",            "bits": 5},
    {"name":"???",                "bits": 1},
    {"name":"Horn Class R2",      "bits": 4},
    {"name":"Horn R2",            "bits": 5},
    {"name":"???",                "bits": 1},

    {"name":"Back Mystic",        "bits": 2},
    {"name":"Back Class",         "bits": 4},
    {"name":"Back",               "bits": 5},
    {"name":"???",                "bits": 1},
    {"name":"Back Class R1",      "bits": 4},
    {"name":"Back R1",            "bits": 5},
    {"name":"???",                "bits": 1},
    {"name":"Back Class R2",      "bits": 4},
    {"name":"Back R2",            "bits": 5},
    {"name":"???",                "bits": 1},

    {"name":"Tail Mystic",        "bits": 2},
    {"name":"Tail Class",         "bits": 4},
    {"name":"Tail",               "bits": 5},
    {"name":"???",                "bits": 1},
    {"name":"Tail Class R1",      "bits": 4},
    {"name":"Tail R1",            "bits": 5},
    {"name":"???",                "bits": 1},
    {"name":"Tail Class R2",      "bits": 4},
    {"name":"Tail R2",            "bits": 5},
    {"name":"???",                "bits": 1}
  ];