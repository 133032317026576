export let colors = [
  {"name":"white", "val":"#fff"},
  {"name":"grey", "val":"#e4e4e4"},
  {"name":"darkgrey", "val":"#848484"},
  {"name":"darkblue", "val":"#759EDB"},
  {"name":"skyblue", "val":"#2DE8F2"},
  {"name":"cyan", "val":"#4CFFDF"},
  {"name":"yellow", "val":"#FFEC51"},
  {"name":"orange", "val":"#FFA12A"},
  {"name":"brown", "val":"#F0C66E"},
  {"name":"mint", "val":"#C5FFD9"},
  {"name":"limegreen", "val":"#CCEF5E"},
  {"name":"yellowgreen", "val":"#EFD636"},
  {"name":"flamingo", "val":"#FFB4BB"},
  {"name":"pink", "val":"#FF9AB8"},
  {"name":"pink", "val":"#FF9AB8"},
  {"name":"watermelon", "val":"#FF7891"},
  {"name":"coral", "val":"#FF7183"},
  {"name":"bloodorange", "val":"#FF6D61"},
  {"name":"red", "val":"#F74E4E"},
  {"name":"lightpurple", "val":"#F5E1FF"},
  {"name":"lavender", "val":"#FDBCFF"},
  {"name":"purple", "val":"#EF93FF"}
  ];

